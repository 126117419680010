import React from 'react';
import './practiceRow.css';
import Button from '../Buttons/Button'; import FullScreenMap from '../Practice/FullScreenMap';
import MaterialIcon, {colorPalette} from 'material-icons-react'; import Badges from '../Cards/Badges';import SvgIcon from '../SvgIcon';
/*
    <div className=""></div>

*/
function ContactRow(props) {
  if (!props.text) {
    return null;
  } else {
    const outer = "den-cont-row-"+props.suffix;
    const iconCont=  "den-cont-row-icon-"+props.suffix;
    return (
      <div className={outer}>
          <div className={iconCont}><SvgIcon color={props.color} icon={props.icon}/></div>
            <div className="">{props.text}</div>
      </div>
    );
  }
}
function ContactBtn(props) {
  if (!props.url) {
    return null;
  } else {
    return (
      <Button icon={props.icon} text={props.text} size="a-btn-size-3" color="a-btn-orange-1" />
    );
  }
}

function ActionBtn(props) {
      const url = props.url ? ((props.icon == "email") ? "mailto:"+props.url : ((props.icon == "whatsapp") ? "https://wa.me/"+props.url : props.url)): null;
      const grow = (window.innerWidth <= 1100) ? "grow-1" : "";
      if (false) {
        return null;
      } else if (props.text && window.innerWidth > 1100) {
          return (
          <div className="den-row-action grow-1"><Button click={props.click} clickHandler={props.clickHandler} id={props.id} size="a-btn-size-40" href={url} text={props.text} color={props.color} className="w100" bot={props.bot}/></div>
        );
      } else {
        return (
          <div className={"den-row-action "+grow}><Button href={url} click={props.click} clickHandler={props.clickHandler} id={props.id} svg={props.icon} color={props.color} iconColor={props.iconColor} className="w100" size="a-btn-size-40-icon w100" bot={props.bot}/></div>
        );
  }

}

function Zone(props) {
  if (!props.zone || window.innerWidth < 900) {
    return null;
  } else {
    const rows = [{text:props.zone, color:"ic-badge-green"}]
    return (
        <Badges rows={rows} />
    );
  }
}

function DentRowBody(props) {
  const primaVisita = <span><span className="w3-bold">Costo prima visita: </span> {(props.costoVisita ? props.costoVisita : "NA")}</span>;
  const whatsapp = props.whatsapp ? ("+" + props.whatsapp) : null;
  return (
    <div className="den-row-body">
          <div className="den-row-b1">
                <div className="den-row-b1-inner">
                      <div className="grow-1">
                          <h3 className="den-row-title"><span>{props.practiceName}</span><br/><span>{props.name}</span></h3>
                          <Zone zone={props.zone} />
                          <div className="den-row-street"><span>{props.street}</span><span> {props.cap} </span><span> {props.city} </span></div>
                          <div className="den-row-contacts">
                                <ContactRow text={props.phone} icon="phone" suffix="phone" color="dodgerblue"/>
                                <ContactRow text={whatsapp} icon="whatsapp" suffix="whatsapp" color="green"/>
                                <ContactRow text={primaVisita} icon="medical_services" suffix="visita" color="red" />
                          </div>
                      </div>
                      <div className="den-row-action-container flx-btw">
                            <ActionBtn  click={true} url={"/practice/id/" + props.id} text="Profilo" color="a-btn-blue-1" clickHandler={props.doctorClick} id={props.id} icon="person" bot={props.bot}/>
                            <ActionBtn  click={props.mapClick}  text="Mappa" color="a-btn-blue-1" icon="place" bot={props.bot}/>
                            <ActionBtn url={props.email} color="a-btn-orange-1" icon="mail" bot={props.bot}/>
                            <ActionBtn url={props.whatsapp} color="a-btn-whatsapp-green" icon="whatsapp" bot={props.bot}/>
                      </div>


                </div>
          </div>

    </div>
  );
}

class PracticeRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {map:false, fullMap:false, scroll:0};
        this.handleMapClick = this.handleMapClick.bind(this);
        this.handleFullScreenMap = this.handleFullScreenMap.bind(this);
    }

    handleMapClick() {
      if (this.state.fullMap) {}
      if (window.innerWidth < 734) {
        this.handleFullScreenMap();
      } else {
        this.setState({map:!this.state.map});
      }
    }

    handleFullScreenMap() {
      if (!this.state.fullMap) {
        const currentScroll = document.documentElement.scrollTop;
        this.setState({scroll:currentScroll, fullMap:true, map:false});
        document.documentElement.classList.add("o-hid");  // Re-enable scroll
      } else {
        document.documentElement.scrollTop = this.state.scroll;
        document.documentElement.classList.remove("o-hid");  // Re-enable scroll
         this.setState({fullMap:false, map:false});
      }

    }

    render() {
      if (!this.props.name) {
        return null;
      } else if (this.state.map){
        return (
            <div className="den-doc-row den-row-defs w3-display-container" >
                  <div className="den-row-map-fullscreesmBtn">
                  <Button text="Visualizza in Full Screen" color="a-btn-blue-1" click={this.handleFullScreenMap}  iconSize="30px" className="" size="a-btn-size-mini"/>
                  <Button text="Chiudi" color="a-btn-blue-1" click={this.handleMapClick}  className="w3-margin-left-8" size="a-btn-size-mini"/>
                  </div>
                  <iframe src={this.props.map} width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy"/>
            </div>
        )
      } else if (this.state.fullMap) {
          return (
              <FullScreenMap map={this.props.map} openMap={this.handleFullScreenMap} fixed={true}/>
          );
      } else {
        const img = !this.props.img ? "/react/home/img/" + "avatar.png" : (this.props.img.match("http") ? this.props.img : "/react/home/img/" + this.props.img);
        return (
          <div className="den-doc-row den-row-defs ">
               <div className="den-row-inner">
                       <div className="den-row-pic"><img src={img} alt={"avatar"}/></div>
                       <DentRowBody {...this.props} mapClick={this.handleMapClick}/>
               </div>

          </div>
        );
      }
    }

}


export default PracticeRow;
