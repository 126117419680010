import React from 'react';import '../CnnArt/Header/headerImg.css';import '../CnnArt/Header/articleHeader.css';
import './practiceHeader.css'; import Breadcrumb from '../CnnArt/Header/Breadcrumb';import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save
/*
    <div className=""></div>
*/

function PracticeHeader(props) {

  const isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  const isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  const isSmarthPhone = useMediaQuery({ maxWidth: 734 });
  const isTablet = useMediaQuery({ maxWidth: 1068, minWidth: 735 });
   if (false) {
     return null;
   } else {

     const bkg = props.src ? {backgroundImage : `url(${props.src})`} : {background: "rgba(13, 117, 197, 0.92"};
     let docImage = {};
     if (props.imgDoctor) {
         if ((typeof props.imgDoctor=== 'string') || (props.imgDoctor instanceof String)) {
             docImage.backgroundImage = `url(/react/home/img/${props.imgDoctor})`;
         } else {
             let img = "";
             if (isSmarthPhone && props.imgDoctor.small) {
               img = props.imgUrl.small;
             } else if (isTablet && props.imgDoctor.medium){
               img = props.imgDoctor.medium;
             } else {
               img = props.imgDoctor.large;
             }
             docImage.backgroundImage = `url(${img})`;
         }

     }
     if (props.bkgPosition) {
       docImage.backgroundPosition = (isSmarthPhone && isPortrait ? props.bkgPosition[0] : (isSmarthPhone && isLandscape ? props.bkgPosition[1] : (isTablet && isPortrait ? props.bkgPosition[2] : (isTablet && isLandscape ? props.bkgPosition[3] : props.bkgPosition[4]))));
     }
     return (
       <header className="rba-header-img flex-cen-cen rba-header-img-defs cn-defs" style={bkg}>
                    <div className="breadcrumb-abs-pos">
                        <div className="breadcrumb-abs-cont">
                            <Breadcrumb clickHandler={props.clickHandler} rows={props.breadcrumb} dark={true}/>
                        </div>
                    </div>
                    <div className="rba-header-img-container flex-cen-cen">
                            <div className="practice-body-main">
                                <h1 className="rba-header-img-title">
                                      <div>
                                            <div className="derm-head">Dermatologo Bari</div>
                                            <div className="derm-title">{props.title}</div>
                                            <div className="derm-subtitle">{props.description}</div>
                                      </div>
                                </h1>
                            </div>
                            <div className="practice-body-side flex-cen-cen w3-hide-small">
                                    <div className="pr-head-img-cont" style={docImage}></div>
                            </div>


                    </div>

      </header>
     );
   }
}


export default PracticeHeader;



// <HeaderImg title="" description="" date="" src=""/>

// import HeaderImg from './CnnArt/Header/HeaderImg';
