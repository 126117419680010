import React from 'react'; import DoveSiamo from './DoveSiamo'; import Abilitazione from './Abilitazione';import Contatti from './Contatti';


/*
    <div className=""></div>
*/

function PracticeSide(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className="practice-body-side">
          <Contatti {...props}/>
          <DoveSiamo {...props} openMap={props.openMap}/>
          <Abilitazione {...props}/>
       </div>
     );
   }
}


export default PracticeSide;
