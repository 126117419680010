import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import "./sideBars.css";
import Link from '../Link';
import BarLi from './BarLi';
//   <div className=""><div>

function RightBar(props) {
  if (!props.right) {
    return <div className="side-bar-right h-100"> </div>
  } else {
    return (
      <div className="side-bar-right h-100">
            <ul className="ul-clear-inline flex-cen-end h-100 tbar-mobile" >
              {props.rightMobile.map((li, index) => <BarLi {...li} key={index} barIconColor={props.barIconColor} clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} />)}
            </ul>
            <ul className="ul-clear-inline flex-cen-end h-100 tbar-std" >
              {props.right.map((li, index) => <BarLi {...li}barIconColor={props.barIconColor} key={index}  clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} />)}
            </ul>
      </div>
    );
  }
}


export default RightBar;
