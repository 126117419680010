import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import "./barLi.css";
import Link from '../Link';
import BtnTopBar from '../Buttons/BtnTopBar';
//   <div className=""><div>

function BarLi(props) {
  const iconColor = props.iconColor ? props.iconColor : props.barIconColor;
  if (false) {
  return null;
  } else if (props.logo) {

    let click = null;
    if (props.click === true) {
      click = [props.clickHandler, props.id];
    } else if (props.click) {
      click = props.click;
    }
    return (
      <li>
          <Link className="logo-cont noselect" click={click}><img className="t-bar-logo" src={props.logo} alt={props.alt}/></Link>
      </li>
    );
  } else {
      const buttonStyle = props.style ? props.style : props.buttonStyle;
      return (
        <li className="w3-animate-opacity " key={props.text}>
            <BtnTopBar {...props} buttonStyle={buttonStyle} iconColor={iconColor}/>
        </li>
      );
  }
}


export default BarLi;
