import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './sideMenu.css'; import './animations.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';
import Previous from './Previous';
import UserProfile from './UserProfile';
import Menu from './Menu';
/*
    <div className=""></div>
*/

class SideMenu  extends React.Component {
      constructor(props) {
          super(props);
          this.state = {rowsArray: [{title: null, rows: this.props.rows}],  status: null, leaving: null, lock: false};
          this.handleSubMenu = this.handleSubMenu.bind(this);
          this.handleBack = this.handleBack.bind(this);
      }

      /*
      rowsArray contains the main menu with no title at index 0, and all the submenues added with the method handleSubMenu while being opened. Method handleBack removes the submenu
      from rowsArray and put it temporarly in state.leaving to handle the animation.
      state.status is null at first load (no submenues loaded, no aniamtion), set to "next" when handleSubMenu il called and set to "prev" when handleBack is called.
      during the animation lock is set to true to avoid pressing two times the same link before it's removed from the animation
      rowsArray chnage trigger a new animation


       The ol list has always two li childs which are inline elements, only one li is visible each time, the other overflows. the second li is created when a submenu is clicked and the ol is translated to the right. when a back action is executed the ol transles to the left to show the previous element.
       Each li represent an entire menu which can have inside different categories each one is another li inside a ul list

       <ol> // ol main
          <li(menu)>
              <ul> // Menu categories container (groups differnet buttons under a title, each button can have a submeni which will be the 2nd li inside the ol main)
                    <li>Category 1</li >
                    <li> Category 2</li >
                    .....
              </ul>
          </li >
          <li (submenu)>
              <ul> Menu categories container
                  <li>Category 3</li >
                  <li> Category 4</li >
                  .....
              </ul>
          /li >
       </ol>
      */


      previouses() {
        let previousesPair = {};
        const length = this.state.rowsArray.length;
        switch (this.state.status) {
              case ("next"): {
                  if (length >= 3) {
                      previousesPair = {li1: this.state.rowsArray[length-3].title, li2: this.state.rowsArray[length-2].title};
                  } else if (length == 2) {
                      previousesPair = {li1: null, li2: "Main Menu"};
                  }
              }
                break;

              case ("prev"): {
                if (length >= 3) {
                    previousesPair = {li1: this.state.rowsArray[length-2].title, li2: this.state.rowsArray[length-1].title};
                } if (length == 2) {
                    previousesPair = {li1: "Main Menu", li2: this.state.rowsArray[length-1].title};
                } else {
                    previousesPair = {li1: null, li2: this.state.rowsArray[length-1].title};
                }
              }
              break;
            default:  previousesPair = {li1: null, li2: null};
        }
        return previousesPair;
      }


      rows() {
        let rows = {};
        const rowsArray = this.state.rowsArray;
        const length = rowsArray.length;
        switch (this.state.status) {
          case "next": {
                rows = {row1: rowsArray[length-2], row2: rowsArray[length-1]};
          }

            break;
          case "prev": {
                rows = {row1: rowsArray[length-1], row2: this.state.leaving};
          }
            break;
          default: rows = {row1: rowsArray[0], row2: null};

        }
        return rows;

      }

      handleLock() {
        this.setState({lock: true});  // set lock
        setTimeout(()=>this.setState({lock: false}), 350);  // and remove it when the animation ends to avoid double clicking
      }

      handleBack() {
          if (this.state.lock == true) return;  // animation in progress, abort call
          this.handleLock();  // set lock during the animation to avoid further clicks
          const rowsArray = this.state.rowsArray;
          const removed = rowsArray.pop(); // remove the last submenu from rowsArray and put it in removed constant. Now the last item in rows array is shown at the end of  "prev" animation
          this.setState({ status: "prev", rowsArray: rowsArray, leaving: removed}); // status "prev" triggers animation from menu stored in state.leaving (to be discarded) to the last indexed in rowsArray
      }

      handleSubMenu(title, rows) {
          if (this.state.lock == true) return;  // animation in progress, abort call
          this.handleLock();  // set lock during the animation to avoid further clicks
          let rowsArray = this.state.rowsArray;
          rowsArray.push({title: title, rows: rows});  // push the submenu in rowsArray
          this.setState({rowsArray: rowsArray, status: "next", leaving: null});  // status next trigger new animation from second last menu in rowsArray to the last just added
          document.getElementById(this.props.scrollID).scrollTop = 0;
      }




      render()  {


          if (!this.props.rows) {
               return null;
             } else {
               const animation =  (this.state.status == "next") ? "side-men-anim-next" : ((this.state.status == "prev") ? "side-men-anim-prev" : "");
               const opacityLeft = (this.state.status == "next") ? "side-menu-fad-opac" : "";
               const opacityRight = (this.state.status == "prev") ? "side-menu-fad-opac" : "";
               return (
                    <div  className={"w3-block side-menu-defs " + (this.props.right ? " side-men-right" : " side-men-left")}>
                            <UserProfile user={this.props.user} />
                            <ol  className={"side-menu-ol-sub " + animation} key={this.state.rowsArray}>
                                <li className={"side-menu-li-sub "+ opacityLeft}> {/*submenu visible when ul is not translated */}
                                      <Previous previous={this.previouses().li1} handleBack={this.handleBack}/>
                                      <Menu rows={this.rows().row1} user={this.props.user}  subHandler={this.handleSubMenu} clickHandler={this.props.clickHandler} iconBarColor={this.props.iconBarColor}/>
                                </li>
                                <li className={"side-menu-li-sub " + opacityRight}> {/*submenu visible when ul is 50% translated */}
                                      <Previous previous={this.previouses().li2} handleBack={this.handleBack}/>
                                      <Menu rows={this.rows().row2} user={this.props.user}  subHandler={this.handleSubMenu} clickHandler={this.props.clickHandler} iconBarColor={this.props.iconBarColor}/>
                                </li>
                            </ol>
                    </div>
               );
             }

      }
}


export default SideMenu;

/* <SideMenu user rows right/>
const title1 =  {title:"Contacts", icons: [{icon: "search", className:"", click: null, href: null}, {icon: "more_horiz", className:"", click: null, href: null}]}; title object contains the title and an array of icons to be displayed on the right
const ulArray = [{title: "title 1", icon: "icon 1",className:"", click: null, href: null, subMenu: sub1},{title: "title 2", icon: "icon 2",className:"", click: null, href: null, subMenu: sub2},... ]; Only one of click, href and subMenu can be used, submenu is the same format of rows
const rows = [{ulArray: ulArray1, title: title1},{ulArray: ulArray2, title: title2}, {...}, ....]; rows are the section of the menu, title is the section title, ulArray is the array of the menu links
right={bol} true is pos right, false to left

*/

// <div className=""></div>
