import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './menu.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';
import CategoryTitle from './CategoryTitle';
import MenuButton from './MenuButton';


function MenuRow(props) {  // a menu is dived in menu rows, each has a category title and several buttons (li of the row)
  if (!props.ulArray) {
    return null;
  } else {
    return (
      <div className="side-menu-row">
                <CategoryTitle {...props.title} iconBarColor={props.iconBarColor}/>
                <div className="w3-padding-bottom-8">
                    <ul className="ul-clear">
                          <div className="">
                              {props.ulArray.map((li, index) => <MenuButton iconBarColor={props.iconBarColor} {...li} key={index} subHandler={props.subHandler} clickHandler={props.clickHandler}/>)}

                          </div>
                    </ul>

                </div>
                <hr className="side-menu-hr"/>
      </div>
    );
  }
}

// Each instance of Menu renders one of the two menu that are li elements of the  ol inline list of SideMenu.  One instance is rendered as previous menu and the other next menu.

function Menu(props) {
  if (!props.rows) {
    return null;
  } else {

    return (
      <div className="side-menu-container">
        {props.rows.rows.map((row, index) => <MenuRow iconBarColor={props.iconBarColor} {...row} key={index} subHandler={props.subHandler} clickHandler={props.clickHandler}/>)}
      </div>

    );
  }
}


export default Menu;
