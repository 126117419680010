import React from 'react';
import '../App.css';
//import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import "./button.css"; import './fillings.css'; import './sizes.css'; import './btnColors.css';
import Link from '../Link';
import DropDown from './DropDown';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save
import MaterialIcon, {colorPalette} from 'material-icons-react';
import SvgIcon from '../SvgIcon';
// <div className=""></div>

function Tooltip(props) {
  if (!props.tooltip) {
    return null;
  } else {
    return (
      <div className="a-btn-tooltip">{props.tooltip}</div>
    );
  }
}
function Text(props) {
  if (props.text ) {
    return <div className={props.icon ? "w3-margin-left-6" : ""}>{props.text}</div>
  } else {
    return null;
  }
}
function ButtonIcon(props) {
  if (!props.icon && !props.svg) {
    return null;
  } else if (props.icon) {
    return (
      <div className="a-btn-icon-container noselect">
          <MaterialIcon icon={props.icon} />
      </div>
    );
  } else {
    return (
      <div className="a-btn-icon-container noselect">
          <SvgIcon icon={props.svg} color={props.color} size={props.iconSize}/>
      </div>
    );
  }
}

function Button(props) {

    /******* Media Queries ****/
    let media = {};
    media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
    media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
    media.isMobile = useMediaQuery({ maxWidth: 734 });
    media.isTablet = useMediaQuery({ maxWidth: 1250, minWidth: 751 });
    media.max1420 = useMediaQuery({ maxWidth: 1420, minWidth: 751 });
    /******* Media Queries ****/

    let click = null; let href = null;
    if ((props.click === true && !props.bot) || (props.click === true && props.bot && !props.href)) {
      click = [props.clickHandler, props.id];
    } else if ((props.click && !props.bot) || (props.click && props.bot && !props.href)) {
      click = props.click;
    }
    if ((props.href && !props.click) || (props.href && props.click && props.bot)) {
      href = props.href;
    }
    const text = (props.textMini && media.max1420) ? props.textMini : props.text;
    const width = props.width ? {width: props.width} : {};
    if (!text && !props.icon && !props.svg) {
      return null;
    } else if ((media.isMobile && props.hideOnMobile) || (media.isTablet && props.hideOnTablet)){
      return null;
    }  else {
          const className = "a-btn noselect " +  (props.className ?  props.className : "") + " " + (props.size ? props.size : "a-btn-size") + (props.dropRows ? " a-btn-drop " : " ")   + (props.filling ? props.filling : "a-btn-filled") +  " " + (props.rounded ? " a-btn-rounded " : " " ) + ((!props.click && !props.href) ? "no-hover a-btn-opac" : "") + " "+ (props.color ? props.color : "a-btn-color-blue-1") ;
          if (props.dropRows) {
              return (
                <Link className="an-inline" click={click} href={href}>
                    <button className={className} style={width}>
                          <div className="flex-cen-cen h-100">
                              <DropDown text={text} dropRows={props.dropRows} clickHandler={props.clickHandler} dropRight={props.dropRight} dropDefs={props.dropDefs}/>
                          </div>
                    </button>
                </Link>
              );
          } else if (props.icon || props.text || props.svg) {
              return (
                <Link className="an-inline" click={click} href={href} noFollow={props.noFollow} newWindow={props.newWindow}>
                    <button className={className} style={width} key={props.icon}>
                            <div className="flex-cen-cen h-100">
                                <ButtonIcon icon={props.icon} svg={props.svg} size={props.iconSize} color={props.iconColor}/>
                                <Text text={text} icon={props.icon} />
                            </div>
                            <Tooltip tooltip={props.tooltip}/>
                    </button>
                </Link>
              );
          } else {
              return null;
          }

    }


}


export default Button;

/*
<Button text="string" icon width="str def 0" click={} href=null filling="class" color="a-btn-blue-1" size="a-btn-size (def)" clickHandler={} id click/>

.a-btn-size {--brdSize: 1px;border-radius: 6px; font-size: 14px; line-height: 20px; padding: 8px 16px; letter-spacing: 0.5px;font-weight: 400; }

Click handling:   if click is true the clickHandler provided by the higher Component will be called with props.id as object, instead if click contains a handler this custom handler will be used instead of clickHandler and without the parameter id
click={true}  true/{customHandler}/null
clickHandler={props.clickHandler}  clickHandler is used if click === true (is a bool and not a custom handler)
id={"pageid"}
*/
