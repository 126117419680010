// import {} from '../Pages/servicesRows';


export var pagesList = {
  home: {name: "Home", url:"/"}


};

  // sitiWeb: {name: "Siti Web", content: creazioneContent, url: "/creazionesitiwebbari", component: "cn-ar"},
