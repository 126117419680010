import React from 'react'; import './contact.css';
import Button from '../Buttons/Button';

/*
    <div className=""></div>
*/


function Abilitazione(props) {
   if (!props.doctors || !props.doctors[0].iscrizione  || !props.doctors[0].posizione) {
     return null;
   } else {
     return (
       <div className="practice-box-container">

            <div className="pr-cont-box ">
              <div className="pr-box-padding pr-box-no-pad-bot">
                  <h4 className="pr-box-title">Abilitazione e Iscrizione FNOMCeO</h4>
                  <ul className="pr-box-ul">
                        <li className="pr-contact-street">{props.doctors[0].sessione}</li>
                        <li className="pr-contact-street">{props.doctors[0].iscrizione}</li>
                        <li className="pr-contact-street">Iscrizione FNOMCeO: {props.doctors[0].posizione}</li>
                  </ul>

              </div>
              <div className="pr-contact-footer"><Button text="Verifica Iscrizione su FNOMCeO" color="a-btn-orange-1" href="https://portale.fnomceo.it/cerca-prof/index.php" newWindow={true} noFollow={true} className="w100" size="a-btn-size-3"/></div>
            </div>
       </div>
     );
   }
}


export default Abilitazione;
