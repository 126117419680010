import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import "./sideBars.css";
import Link from '../Link';
import BarLi from './BarLi';
//   <div className=""><div>

function LeftBar(props) {
    if (!props.left) {
      return <div className="side-bar-left h-100"> </div>
    } else {
      return (
        <div className="side-bar-left h-100 ">
              <ul className="ul-clear-inline flex-cen-start h-100 tbar-std" >
                {props.left.map((li, index) => <BarLi {...li} key={index}  dropRight={props.dropRight} clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} barIconColor={props.barIconColor} bot={props.bot}/>)}
              </ul>
              <ul className="ul-clear-inline flex-cen-start h-100 tbar-mobile" >
                {props.leftMobile.map((li, index) => <BarLi {...li} key={index}  dropRight={props.dropRight} clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} barIconColor={props.barIconColor} bot={props.bot}/>)}
              </ul>
        </div>
      );
    }

}


export default LeftBar;
