import React from 'react';
import './header.css';

/*
    <div className=""></div>
*/

function Header(props) {
   if (false) {
     return null;
   } else {
     const bkg = props.src ? {backgroundImage: `url(${props.src})`} : {};
     const imgSize = props.imgclass ? props.imgclass : "pr-head-img-size";
     return (
       <header className="pr-header-container pr-header-defs">
          <div className="pr-header-content">
                <div className="pr-header-text">
                    <h1 className="pr-header-h1">
                          <div className="pr-header-h1-1st">{props.title1}</div>
                          <div className="pr-header-h1-2nd">{props.title2}</div>
                          <div className="pr-header-h1-3rd"></div>
                    </h1>
                </div>
                <div className="pr-header-img"><img src={props.src} alt={props.alt} className={imgSize}/></div>
          </div>

       </header>
     );
   }
}


export default Header;
