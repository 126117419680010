import React from 'react';import Button from '../Buttons/Button';import './practice.css';


/*
    <div className=""></div>
*/

function FullScreenMap(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className={"practice-container pr-fullMap-cont " + (props.fixed ? "fullMap-fixed": "")}>
            <div className="pr-fullMap-action"><Button text="Chiudi Mappa" color="a-btn-blue-1" click={true} clickHandler={props.openMap} id={false}  className="" size="a-btn-size-3"/></div>
            <iframe src={props.map} width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy"/>
       </div>
     );
   }
}


export default FullScreenMap;
