import React from 'react'; import './contact.css';
import Button from '../Buttons/Button';

/*
    <div className=""></div>
*/


function Titoli(props) {
   if (!props.doctors ) {
     return null;
   } else {
     return (
       <div className="practice-box-container">
            <div className="pr-cont-box pr-box-padding">
                      <h4 className="pr-box-title">Titoli conseguiti</h4>
                      <ul className="pr-box-ul">{props.doctors[0].titoli.map((row,index)=><li key={index}>{row.text}</li>)}</ul>

            </div>
       </div>
     );
   }
}


export default Titoli;
