import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';

/*
<div>

</div>
*/

function Paragraph(props) {
    if (props.text) {
      return (
        <div className="acFu-parag-container acFu-parag">
          {props.text}
        </div>
      );
    } else {
      return null;
    }
}



function Sect(props) {
  return <div className={"w3-section "+ props.cl}>{props.children}</div>;
}

function Icon(props) {
  if (!props.icon || !props.size) {
    return null;
  } else {
    const size = {width: props.size, height: props.size, lineHeight: props.size};
    const className = props.className ? props.className : "";

    return (
      <div className={className + " noselect"} style={size}>
          <MaterialIcon  size={props.size} icon={props.icon} color={props.color}/>
      </div>
    );
  }
}


// <Picture className img={small:"",medium:"",large:""} />
function Picture(props) {
    const className = props.className ? props.className : "";
    const img = props.img;
    if (!img) {
        return null;
    } else if (typeof img === 'string' || img instanceof String)  {
        return <picture className={className}><img src={img} /></picture>;
    } else if (!img.large || !img.small || !img.medium)  {
        return null;
    } else {
        return (
            <picture className={className}>
                <source srcset={props.img.small} media="(max-width:734px)"/>
                <source srcset={props.img.medium} media="(max-width:1068px)"/>
                <source srcset={props.img.large} media="(min-width:0px)"/>
                <img src={props.img.large} />
            </picture>
      );
    }
}


export {Paragraph, Sect, Icon, Picture};
