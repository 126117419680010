import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './categoryTitle.css';
import SvgIcon from '../SvgIcon';
import Link from '../Link';



function TitleIcon(props) {
  if (!props.icon) {
    return null;
  } else {
    return (
      <div className="side-menu-title-ic">
            <Link  className={"d20 " + props.className} href={props.href} click={props.click}><div className="d20"><SvgIcon icon={props.icon}  color="rgb(101, 103, 107)" /></div></Link>
      </div>
    );
  }
}

function CategoryTitle(props) {
  if (!props.title) {
    return null;
  } else {
    const Icons = (props) => (props.icons ? props.icons.map( (icon,index) => <TitleIcon {...icon} key={index} color={props.color}/> ) : null);
    return (
      <div className="flex-cen-cen side-menu-title-cont">
            <div className="side-menu-title">
                  {props.title}
            </div>
            <Icons icons={props.icons} color={props.iconBarColor} />
      </div>
    );
  }

}

export default CategoryTitle;
