import React from 'react'; import './contact.css';
import Button from '../Buttons/Button';

/*
    <div className=""></div>
*/


function Patologie(props) {
   if (!props.rows) {
     return null;
   } else {
     return (
       <div className="practice-box-container">
            <div className="pr-cont-box pr-box-padding">
                      <h4 className="pr-box-title">{props.title}</h4>
                      <ul className="pr-patologie pr-box-ul">{props.rows.map((row,index)=><li key={index}>{row.title}</li>)}</ul>

            </div>
       </div>
     );
   }
}


export default Patologie;
