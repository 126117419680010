import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import "./centerBar.css";
import Link from '../Link';
import BarLi from './BarLi';
import './topBar.css';
//   <div className=""><div>

function CenterBar(props) {
  if (!props.centerLeft) {
  return null;
  } else {
    return (
      <div className="c-bar h-100">
          <ul className="ul-clear-inline tbar-center-mobile tbar-mobile" >
            {props.centerMobile.map((li, index) => <BarLi {...li} barIconColor={props.barIconColor} key={index}  clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} />)}
          </ul>
          <div className="tbar-center tbar-std">
                <ul className="ul-clear-inline flex-cen-start h-100" >
                  {props.centerLeft.map((li, index) => <BarLi {...li}barIconColor={props.barIconColor}  key={index}  clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} />)}
                </ul>
                <ul className="ul-clear-inline flex-cen-start h-100" >
                  {props.centerRight.map((li, index) => <BarLi {...li} barIconColor={props.barIconColor} key={index}  clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} />)}
                </ul>
          </div>

      </div>
    );
  }
}


export default CenterBar;
