import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import '../colors.css';
import SvgIcon from '../SvgIcon';
import './slidingPanel.css';

function SlidingPanel(props) {

    function handleClose() {
      props.handleClose("close");
    }

    if (!props.show) {
      return null;
    } else {
      const animation = (props.show == "open") ? " sld-pan-fade-in " : " sld-pan-fade-out ";
      const animationBtn = (props.show == "open") ? " sliding-panel-close-button-in " : " w3-hide ";
      const direction = (props.direction == "left") ? " sld-pan-left " :  " sld-pan-right ";
      const closeIconPos = (props.direction == "right") ? " flex-cen-start " : " flex-cen-end ";
      const Overlay = (props) => (props.show === "open") ? <div className="sld-pan-overlay" onClick={props.handleClose}></div> : null;
      return (
          <div  className={"sld-pan-cont " + props.width + direction}>
                <div id={props.scrollID} className="sld-pan-abs-cont"> {/*Without this absolute container the close button lower z-index doens't work and overlaps the inner-cont with higher z-index ?????*/}
                      <div className={"sld-pan-close-btn  pointer " + animationBtn + closeIconPos} onClick={handleClose}>
                          <div className="">
                            <div className="d24"><SvgIcon icon="close"  color="white" /></div>
                          </div>
                      </div>
                      <div  className={"sld-pan-inner " + animation}>
                          <div  className="sld-pan-child-cont">
                            {props.children}
                          </div>
                      </div>
                      <Overlay show={props.show} handleClose={handleClose}/>
                </div>
          </div>
      );
    }
}

export default SlidingPanel;


// <SlidingPanel show="open"/"close"{false}  width handleClose direction=left/right/>
// width: css class from calling component specifing the width in --fx-side-width, example: .review-mod-width {--fx-side-width: 35%;}
// show="open"/"close"/{false} the calling component sets it to open for fade in opening, close for fade out closing
// handleClose sends "closed" to higher component to update the state of show, which is kept outside and handled inside via prop show.
