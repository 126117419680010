import React from 'react';
import './footer.css';

function Footer(props) {
   if (false) {
     return null;
   } else {
     return (
          <div className="footer  w3-center">Dermatologia Bari © 2022
            Sito Web a fini non commerciali e senza pubblicità. <br/>Privacy totale: no cookie, no tracker, nessun dato personale memorizzato o condiviso con terzi!
           </div>
      );
   }
}


export default Footer;
