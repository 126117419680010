import React from 'react';
import './luke.css';
import './w3.css';
import './App.css';
import FixedMenu from './Bars/FixedMenu'; import Practice from './Practice/Practice'; import Home from './Home/Home'; import './CnnArt/cnAr.css';
import Footer from './Footer';import TopBar from './TopBar/TopBar';import Link from './Link';
import {fixedMenuRows} from './Options/fixedMenuData.js'; import {topBar} from './Options/topBar.js'; import {pagesList} from './Options/pagesList.js';
import MaterialIcon, {colorPalette} from 'material-icons-react';
//   "homepage": "https://www.dermatologiabari.com/react/home/",
/*
    <div className=""></div>
*/






class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {pages: null, fixedMenu: null, selectedPractice: null, previousPractices: null};
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleFixedMenu = this.handleFixedMenu.bind(this);
        this.handleDoctorClick = this.handleDoctorClick.bind(this);
    }


    pagesList = pagesList;



    handlePageClick(id) {

        let pages = this.state.pages;
        const page = this.pagesList[id] ? this.pagesList[id] : null;
        // pages array starts empty, at the first next page we add two elemnts,the home and the next pège,otherwise we add onli the next, when we go back we pop the last
        // pages = [{id: "", name: "", scroll: scroll},{id: "", name: "", scroll: scroll}...];
        if (id == "mainMenu") {
          this.handleFixedMenu("open");
          return;
        } else if (id === "home") {
          pages = [{id: "home", name: "Home", scroll: 0, content: null, url: "/", component: "home"}];
        } else if (id == "back") {  // clicked back -> we set the srollTop to the saved value
          pages.pop();
          setTimeout(()=>(document.documentElement.scrollTop = pages[pages.length-1].scroll), 50)
          //document.documentElement.scrollTop = pages[pages.length-1].scroll;
        } else if (id == "practice"){
          ;
        }  else  {  // it's a forward page, so we save the current scroll to use when we go back
          const currentScroll = document.documentElement.scrollTop;
          if(!pages) { // if we are leaving home (first page) we inizialied the page array with home
            pages = [{id: "home", name: "Home", scroll: currentScroll, content: null, url: "/", component: "home"}];
          } else { // if it's not the first page we add the scroll to the last element of the page array
            pages[pages.length-1].scroll = currentScroll;
          }
          setTimeout(()=>(document.documentElement.scrollTop = 0), 10)  // we set the scroll to 0 before going to the next page but tith a 50 ms timeout so it's applied after the setstate
          pages.push({id:id, name: page.name ? page.name : id, scroll: 0, content: page.content, url: page.url, component: page.component});  // not we push the next page in pages array and set state
        }
        this.setState({pages: pages,fixedMenu:null, selectedPractice:null});
    }

    handleFixedMenu(action) { // action is "open" or close
        this.setState({fixedMenu: action});
        if (action == "close") {
            const menTimer = setTimeout(() => this.setState({fixedMenu: false}), 550);
            document.documentElement.classList.remove("o-hid");  // Re-enable scroll

        } else if (action == "open") {
           document.documentElement.classList.add("o-hid"); // Disable scroll on content hidden by the overlay
        }
    }

    handleDoctorClick(id){
        this.setState({selectedPractice: id});
        document.documentElement.scrollTop = 0;
    }



    componentDidMount() {
        if (this.props.noSelect) {
          document.oncontextmenu = function() {return false;} ;
        }

        if (this.props.practice) {
          this.setState({selectedPractice: this.props.practice});
        }
        if (this.props.page) {
          const page = this.pagesList[this.props.page];
          const pages = [{id: "home", name: "Home", scroll: 0, content: null, url: "/", component: "home"},{id: this.props.page, name: page.name, content: page.content, component: page.component, url: page.url}];
          this.setState({pages: pages});
        } else if (this.props.doc) {
          this.handleDoctorClick(this.props.doc);
        }

    }

    render() {

        const currentPage = this.state.selectedPractice ? "" : ((!this.state.pages) ?  "start" : this.state.pages[this.state.pages.length -1].component);
        const content = !this.state.pages ? {} : this.state.pages[this.state.pages.length -1].content;
        const back = (!this.state.pages || (this.state.pages.length < 2)) ? null : this.state.pages[this.state.pages.length -2]; //
        const enhanced = this.props.bot? "" : "enhanced";
        const topBarposition =  "fixed";
        const topBarStyle =  "filled-dark";
        const barColor =  "a-btn-ukr-flag";
        const bodyPadding =  " body-pad "; // Apart from the home page every page has a fixed topbar so we apply to those pages a top padding of 60px to compensate the bar height
        return (
                <div className={"col-fBlu-l5 " + bodyPadding + enhanced + (this.props.noSelect ? " noselect" : "")}>
                    <TopBar clickHandler={this.handlePageClick} barIconColor="#FFD700" {...topBar} position={topBarposition} buttonStyle={topBarStyle}  color={barColor} back={back} bot={this.props.bot} pagesList={this.pagesList}/>
                    <Home show={currentPage} bot={this.props.bot} doctorClick={this.handleDoctorClick} pagesList={this.pagesList} rows={this.props.practices} patologie={this.props.patologie} metodologie={this.props.metodologie}/>
                    <Practice practice={this.state.selectedPractice} bot={this.props.bot} doctorClick={this.handleDoctorClick}  rows={this.props.practices} />
                    <FixedMenu handleClose={this.handleFixedMenu} show={this.state.fixedMenu} rows={fixedMenuRows} clickHandler={this.handlePageClick} iconBarColor="dodgerblue"/>
                    <Footer />
                </div>
                );
    }

}
//<Apl />
//<TopBarC clickHandler={this.handleTopBarClick} type="BH" back={back} color="a-btn-blue-1" pageClick={this.handlePageClick} />
export default App;
