import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './badges.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';

/*
    <div className=""></div>
*/
function Icon(props) {
  if (!props.icon) {
    return null;
  } else {
    return <div className="ic-badge-icon "><MaterialIcon icon={props.icon} color="#fff0e0" size="17px" /></div>;
  }
}

function Tooltip(props) {
  if (!props.text) {
    return null;
  } else {
    return (
      <div className="ic-badge-tooltip">
            <div className="ic-badge-tooltip-inner">
                  <div className="ic-badge-tooltip-wrapper">{props.text}</div>
            </div>
      </div>
    );
  }
}

function Row(props) {
    if (false) {
      return null;
    } else {
      const size = props.vPadding ? {padding: props.vPadding + " 10px"} : {};
      const color = "ic-badge-green";
      return (
        <div className="ic-badge">
            <div className={"ic-badge-cont flex-cen-cen " + color} style={size}>
                <Icon icon={props.icon} />
                <div>{props.text}</div>
            </div>
            <Tooltip text={props.tooltip} />
        </div>
      );
    }
}



function Badges(props) {
   if (!props.rows) {
     return null;
   } else {
     const style = {margin: props.margin ? props.margin : "0 0 5px 0 "};
     return (
          <div className="w3-row" style={style}>
                  {props.rows.map((row, index) => <Row {...row} key={index}/>)}
          </div>
     );
   }
}

export default Badges;
