import React from 'react';
import './patologie.css';import Button from '../Buttons/Button';

/*
    <div className=""></div>
*/
function Patologia(props) {
  if (false) {
    return null;
  } else {
    const img = props.img ? <img src={"/react/home/img/"+props.img} alt={props.title} /> : null;
    return (
          <div className="patologia-cont">
              <h3 className="pat-title">{props.title}</h3>
              <div className={"pat-img " + (props.noImg ? "w3-hide" : "")}>{img}</div>
              <div className="pat-text">{props.text}</div>
              <div className="pat-wiki"><Button href={props.wikiUrl} size="a-btn-size-40"  text="Approfondisci su Wikipedia" color="a-btn-blue-1" className="w100" bot={props.bot}/></div>
          </div>
    );
  }
}


function Patologie(props) {
   if (!props.patologie) {
     return null;
   } else {
     return (
       <section className="w3-section-64 patologie-section-cont">
            <h2 className="patologie-header-home">{props.title}</h2>
            <div className="patologie-rows-cont">
                {props.patologie.map((row,index)=> <Patologia key={index} {...row} noImg={props.noImg}/>)}
            </div>
       </section>
     );
   }
}


export default Patologie;
