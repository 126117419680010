



const fixedMenuRows = [
    {ulArray: [{title: "Home", icon:"home", click: true, id: "home"},{title: "Contact Us", icon:"mail",href:"mailto:info@dermatologiabari.com", click: false},{title: "Abuse", icon:"mail",href:"mailto:info@dermatologiabari.com", click: false}], title: null}
];

export {fixedMenuRows};

/*
pagesList = {
  sitiWeb: {name: "Siti Web", content: creazioneContent, url: "/creazionesitiwebbari", component: "cn-ar"},
  responsive: {name: "Responsive Design", content: null, url:"./#pma"},
  seo: {name: "Seo", content: seoContent, url:"/consulenteseobari", component: "cn-ar"},
  landingPage: {name: "Landing Page", content: lanPageContent, url:"/landingpagebari", component: "cn-ar"},
  animations: {name: "Animazioni", content: animationsContent, url:"/webanimationbari", component: "cn-ar"},
  reactive: {name: "Reactive Design", content: reactiveContent, url:"/reactivedesignbari", component: "cn-ar"}
};
*/


/*

//const sub1 = [{ulArray: ulArray2, title: title},{ulArray: ulArray2, title: title}];
const ulRealizzazione = [{title: "Realizzazione Siti Web Bari e App", icon: "web", className:"", click: true, href: null, subMenu: null, id: "sitiWeb"},{title: "Landing Page Bari", icon:"flight_land", className:"", click: true, href: null, subMenu: null, id: "landingPage"}];
const ulSeo = [{title: "Consulente Seo Bari", className:"", click: true, href: null, subMenu: null, id: "seo", icon:"person_search"}];
const ulDesign = [{title: "Reactive Web Design Bari", className:"", click: true, href: null, subMenu: null, id: "reactive", icon: "bolt"}, {title: "Responsive Web Design Bari", className:"", click: true, href: null, subMenu: null, id: "responsive", icon: "smartphone"},{title: "UX Design Bari", className:"", click: true, href: null, subMenu: null, id: "ux", icon: "settings_accessibility"},{title: "Animazioni Web / Web Animations", icon:"animations", className:"", click: true, href: null, subMenu: null, id: "animations"}];

const fixedMenuRows = [
  {ulArray: [{title: "Home", icon:"home", click: true, id: "home"},{title: "Contattaci", icon:"email",href:"mailto:info@reactbari.com", click: false}], title: null},
  {ulArray: ulRealizzazione, title: {title: "Siti Web e Web App"}},
  {ulArray: ulSeo, title: {title: "Seo - Search Engine Optimization"}},
  {ulArray: ulDesign, title: {title: "Web Design Bari"}}

];



*/
