import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

function arrayToObj(arr, mapping) {
   let out = []; let dummyObg;
   if (Array.isArray(arr)) {
     for (let i = 0; i < arr.length ; i++) {
          dummyObg = {};
          for (let m = 0; m < mapping.length ; m++) {
            if (Array.isArray(mapping[m])) {
              dummyObg[mapping[m][0]] = arrayToObj(arr[i][m], mapping[m][1]);
            } else {
              dummyObg[mapping[m]] = arr[i][m];
            }

          }
            out.push(dummyObg);
          }
          return out;
      } else {
        return null;
      }
}
const doctors = ["doctors",["id","name","surname","sex","posizione","sessione","iscrizione","img","imgPos", ["titoli",["id","docID","icon","img","text","type"]]]];
const patologieMap = ["patologie",["id","title"]];
const metodologieMap = ["metodologie", ["id","title"]];
const insurances = ["insurances", ["id","title"]];
const mapping = ["numericID","id","practiceName","name","subtitle","pIva","lingue","street","cap","zone","city","phone","whatsapp","email","img","imgPos","heroImg","heroImgPos","map","website",doctors, patologieMap,metodologieMap, insurances];
const patologieArrayMap = ["id","title","subtitle","text","wikiUrl","img","imgAthor","imgLicenceUrl"];

let props = {};
props.practices = arrayToObj(window.server[0], mapping);
props.bot = window.server[1];
props.practice = window.server[2];
props.page = window.server[3];
props.patologie = arrayToObj(window.server[4], patologieArrayMap);
props.metodologie =  arrayToObj(window.server[5], patologieArrayMap);  // same mapping as patologie

ReactDOM.render(
  <React.StrictMode>
    <App {...props} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
