import Header from './Header'; import PracticeRow from './PracticeRow';import './home.css'; import Patologie from './Patologie';

function Home(props) {
  if (props.show != "home" && props.show != "start") return null;
  return (
    <div className="home-defs">
          <Header src="/react/home/img/dermatologist.png" title1=<span>Cerchi un Dermatologo a Bari?</span> title2="Su Dermatologia Bari abbiamo i migliori medici Dermatologi e centri estetici di Bari e provincia!"/>

          <div className="dent-body-cont cn-defs w3-section-64">
                {props.rows.map((row,index) => <PracticeRow {...row} key={index} doctorClick={props.doctorClick} bot={props.bot} />)}
          </div>
          <Patologie patologie={props.patologie} title="Principali patologie Dermatologiche"/>
          <Patologie patologie={props.metodologie} noImg={true} title="Metodologie e trattamenti Dermatologici"/>
    </div>
  );
}

export default Home;

//
