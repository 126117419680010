import React from 'react';import Titoli from './Titoli'; import Patologie from './Patologie'; import TextBox from './TextBox';


/*
    <div className=""></div>
*/

function PracticeMain(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className="practice-body-main">
          <Titoli {...props} />
          <Patologie title="Patologie trattate" rows={props.patologie} />
          <Patologie title="Metodologie diagnostiche e terapeutiche" rows={props.metodologie} />
          <Patologie title="Assicurazioni convenzionate" rows={props.insurances} />
       </div>
     );
   }
}


export default PracticeMain;
