import React from 'react';
import './breadcrumb.css';
import Link from '../../Link.js';
/*
    <div className=""></div>
*/
function Li(props) {
  const click = props.click ? [props.clickHandler, props.id] : false;
    return (
        [
          <li className="breadcrumb-link"><Link className="an-inline " click={click} href={props.href}>{props.text} </Link></li>,
          <svg className="breadcrumb__icon"  viewBox="0 0 128 128" ><path d="M54.3 96a4 4 0 0 1-2.8-6.8L76.7 64 51.5 38.8a4 4 0 0 1 5.7-5.6L88 64 57.2 94.8a4 4 0 0 1-2.9 1.2z"></path></svg>
        ]
    );
}

function Breadcrumb(props) {
   if (!props.rows) {
     return null;
   } else {
     const color = !props.dark ? "breadcrumb-bright" : "breadcrumb-dark";
     return (
       <nav id="breadcrumb ">
           <ol  className={"rba-breadcrumb " + color}>
                {props.rows.map((row,index) => <Li {...row} clickHandler={props.clickHandler} key={index} />)}
           </ol>
       </nav>
     );
   }
}


export default Breadcrumb;
/*
<Breadcrumb clickHandler rows />
rows = [{click:, href:, id:, text:},{click:, href:, id:, text:},...]
*/
