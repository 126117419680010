function PrBoxEl(props) {
  if (!props.text) {
    return null;
  } else {
    return (
      <div className="pr-contact-street"><h4 className="pr-contact-street an-inline">{props.h4} :</h4><div className="an-inline pr-cont-street-text"> {props.text}</div></div>
    );
  }
}

export default PrBoxEl;
