import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './userProfile.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Link from '../Link';


function UserProfile(props) {
  if (!props.user) {
    return null;
  } else {
    return (
      <div className="w3-container-24 flex-cen-cen sid-men-user">
            <div className="d25 ">
                <img className="w100 w3-circle" src="https://images-eu.ssl-images-amazon.com/images/S/amazon-avatars-global/default._CR0,0,1024,1024_SX48_.png" />
            </div>
            <div className="sid-men-user-txt">
            Ciao, {props.user}
            </div>
      </div>

    );
  }
}

export default UserProfile;
