import {pagesList} from './pagesList.js';
export var topBar = {center:{}, left:{},right:{}};


topBar.center.centerLeft = [
  {logo:"/react/home/img/dermatologiabari.png", alt:"Dermatologia Bari", click: true, id:"home", href:"/"},

];

topBar.center.centerRight = [
  {svg:"home", click: true, id:"home", href:"/", tooltip:"Home"},{svg:"arrow_upward", tooltip:"Scroll Top", href:"#root"},
  {svg: "menu", click: true, id:"mainMenu", tooltip: "Menu"}];

topBar.center.centerMobile = [
  {logo:"/react/home/img/dermatologiabari.png", alt:"Dermatologia Bari", click: true, id:"home", href:"/"}
];

topBar.right.right = [];

topBar.right.rightMobile = [
  //{icon:"email", href:"mailto:info@dermatologiabari.com"},
  {svg: "menu", click: true, id:"mainMenu"}
];

topBar.left.left = [];

topBar.left.leftMobile = [ // back button will be eventually added as first element by the topBar component itself if props.back is set
  {svg:"home", click: true, id:"home", href:"/"}
];






/*
topBar.center.centerLeft = [
  {logo:"/react/home/img/reactbari.png", alt:"reactbari.com", click: true, id:"home", href:"/"},
  {text: "Web Design", drop: true,
        dropRows:[{text:"Creazione Siti Web",click: true, id: "sitiWeb", href: pagesList.sitiWeb.url},
              {text:"Reactive Web Design",click: true, id: "reactive", href: pagesList.reactive.url},
              {text:"Responsive Web Design",click: true, id: "responsive", href: pagesList.responsive.url},
              {text:"Landing Page",click: true, id: "landingPage", href: pagesList.landingPage.url},
              {text:"UX Design",click: true, id: "ux", href: pagesList.ux.url}
              ]
  },
  {text:"Consulente Seo",textMini:"Seo", click: true, id: "seo", href: pagesList.seo.url},
  {text:"Animazioni", click: true, id: "animations", href: pagesList.animations.url}
];

topBar.center.centerRight = [
  {icon:"home", click: true, id:"home", href:"/", tooltip:"Home"},{icon:"arrow_upward", tooltip:"Scroll Top", href:"#root"},
  {icon: "menu", click: true, id:"mainMenu", tooltip: "Menu"}];

topBar.center.centerMobile = [
  {logo:"/react/home/img/reactbari.png", alt:"reactbari.com", click: true, id:"home", href:"/"}
];

topBar.right.right = [
  {text: "Contattaci", href:"mailto:info@reactbari.com", style:"filled",color:"a-btn-orange-1"}
];

topBar.right.rightMobile = [
  {icon:"email", href:"mailto:info@reactbari.com"},
  {icon: "menu", click: true, id:"mainMenu"}
];

topBar.left.left = [ // back button will be eventually added as first element by the topBar component itself if props.back is set

];

topBar.left.leftMobile = [ // back button will be eventually added as first element by the topBar component itself if props.back is set
  {icon:"home", click: true, id:"home", href:"/"}
];
*/

/*
topBar.center.centerLeft = [];

topBar.center.centerRight = [];

topBar.center.centerMobile = [];

topBar.right.right = [];

topBar.right.rightMobile = [];

topBar.left.left = [];

topBar.left.leftMobile = [];

*/
