import React from 'react';
import '../App.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import '../luke.css';
import '../w3.css';
import "./topBar.css";
import Link from '../Link';
import LeftBar from './LeftBar'; import CenterBar from './CenterBar'; import RightBar from './RightBar';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save
//   <div className=""></div>

function Overlay(props) {
    if (props.overlay === true) {
      return <div className="top-bar-container-overlay"></div>;
    } else if (props.overlay) {
      return <div className={props.overlay}></div>;
    } else {
      return null;
    }
}


function TopBar(props) {
  //media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
  //media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
  const isSmarthPhone = useMediaQuery({ maxWidth: 734 });
  const isTablet = useMediaQuery({ maxWidth: 1068, minWidth: 735 });
  /******* Media Queries ****/


  if ( (!props.center || (!props.center.centerLeft && !props.center.centerRight)) || (!props.left || !props.left.left) || (!props.right || !props.right.right)) {
  return null;
  } else {
    let position = " ";
    if (props.position == "fixed") {
      position = "top-bar-fixed ";
    } else if (props.position == "absolute") {
      position = "top-bar-absolute ";
    }
    const container = "top-bar-container  " + position + (props.defs ? props.defs : ((props.position == "absolute") ? "top-bar-defs-abs" : "top-bar-defs")) + " " + props.color;
    //const centerBig = [{text:"Links",icon:"home", click: true, id:3},{text:"Activities",click: true, id:4},{text:"Log In",click: true, id:5},{text:"Account", drop: true,color:"white", dropRows: [{text: "Link 1",click: true, id:6},{text: "Link 1",click: true, id:7},{text: "Link 2",click: true, id:8},{text: "Link 3",click: true, id:9}]},{text:"Log In",click: true, id:10}];
    // const centerSmall = [{logo: "https://www.registrarcorp.com/wp-content/uploads/2021/02/Amazon-logo.png", click: true, id:"home"}];

    const back = (props.back) ? [{svg: "arrow_back", text: props.back.name, click: true, id: "back", href:props.back.url, style:"filled", color:"a-btn-blue-1"}] : [];
    const backMobile = (props.back) ? [{svg: "arrow_back", click: true, id: "back", href:props.back.url}] : [];

    const left = {left: back.concat(props.left.left), leftMobile: backMobile.concat(props.left.leftMobile)};

     //[{icon:"email", text:"Email", href: "mailto:luca@reactbari.info",  tooltip: null}];
    const leftBarDropRight = false; // if the drop down is at least the third element leave it to false, otherwise if the drop down overflows to the left set it to this to true in order to expand to the right side

    return (
      <div className={container}>
          <div className="tbar-flex-cont">
              <LeftBar {...left} clickHandler={props.clickHandler} dropRight={leftBarDropRight} buttonStyle={props.buttonStyle} bot={props.bot} barIconColor={props.barIconColor}/>
              <CenterBar  {...props.center} clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} barIconColor={props.barIconColor}/>
              <RightBar {...props.right} clickHandler={props.clickHandler} buttonStyle={props.buttonStyle} bot={props.bot} barIconColor={props.barIconColor}/>
              <Overlay overlay={props.overlay} />
          </div>
      </div>
    );
  }
}


export default TopBar;

/*
,{icon:"home", click: true, id:"home", href:"/"}
<TopBar color position defs left right center buttonstyle overlay clickHandler/>
overlay={true || false || "className"} // If the top bar is positioned on top of an image add an overlay to improve the readibility. Set true for the default one or enter a custom class name
          defaul overlay class: .top-bar-container-overlay {content: "";  position: absolute;  height: 100%;  top: 0;  left: 0;  right: 0;  background-image: linear-gradient(0deg,hsla(0,0%,100%,0),rgba(0,0,0,.5) 90%, rgba(0,0,0,.7) 100%);}
color="class" : use one of the button colors classes from Button Component. Some buttone styles may not need color prop because have fixed colors
position = {false}||"fixed"|| "absolute" : Leave empty or {false} for relative position, otherwise "fixed"|| "absolute"
defs = "className" -> set a new default defs class to subsitute the default one: .top-bar-defs {--tBarHeight: 50px; --barBkg:  hsl(var(--h),var(--s),var(--l)); --intraMargin:16px; --sidesMergins: 16px; --c-barWidth: 1380px; }
buttonStyle="string" -> choose one button style from button Component
left, right,center : array of objects containing the buttons data
*/

/**** Button object
If the buttons contains text and icons put fill both otherwise fill only one. Icon get displayed first. For frop donw don't put the icon name, just the text. The drop icon is automatic when dropRows is filled
{
  icon: "iconName", // if it's a d
  text: "str",


}




*****/
