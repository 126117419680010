import React from 'react'; import './contact.css';
import Button from '../Buttons/Button';import PrBoxEl from './PrBoxEl';

/*
    <div className=""></div>
*/
function Map(props) {
  if (!props.map) {
    return null;
  } else {
    return (
        <div className="cont-map-cont" >
                <iframe src={props.map} width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy"/>
        </div>
    );
  }
}

function DoveSiamo(props) {
   if (false) {
     return null;
   } else {
     return (
       <div className="practice-box-container">
            <div className="pr-cont-box">
                  <div className="pr-contact-header">
                      <h3 className="pr-box-title">Dove Siamo</h3>
                      <h3 className="pr-contact-name">{props.name}</h3>
                        <PrBoxEl h4="Indirizzo" text={props.street} />
                        <PrBoxEl h4="Zona" text={(props.zone ? props.zone + " - "  : "") + (props.cap ? props.cap + " - "  : "") + props.city} />
                        <PrBoxEl h4="Partita Iva" text={props.pIva} />
                  </div>
                  <Map map={props.map}/>
                  <div className="pr-contact-footer"><Button text="Apri Mappa in Full Screen" color="a-btn-orange-1" clickHandler={props.openMap} click={true} id={true} className="w100" size="a-btn-size-3"/></div>
            </div>
       </div>
     );
   }
}


export default DoveSiamo;
