import React from 'react';import FullScreenMap from './FullScreenMap';
import './practice.css';import PracticeHeader from './PracticeHeader';import PracticeMain from './PracticeMain'; import PracticeSide from './PracticeSide';

/*
    <div className=""></div>
*/

class Practice extends React.Component {
      constructor(props) {
          super(props);
          this.state = {fullMap: false};
          this.handleMapClick = this.handleMapClick.bind(this);

      }

      handleMapClick(open) {
        if (open) {
          document.documentElement.scrollTop = 0;
          this.setState({fullMap: true});

        } else {
          this.setState({fullMap: false});
        }

      }



      render() {
        if (!this.props.practice) {
          return null;
        } else {

              var row = null;
              for (let i=0; i < this.props.rows.length; i++) {
                if (this.props.rows[i].id === this.props.practice) {
                  row = this.props.rows[i];
                }
              }
              if (!row) {
                return null
              } else if (!this.state.fullMap){

                return (
                    <div className="practice-container practice-def ">
                         <PracticeHeader title={row.practiceName + " " + row.name} description={row.subtitle} imgDoctor={row.img} />
                         <div className="practice-body">
                             <PracticeMain {...row}/>
                             <PracticeSide {...row} openMap={this.handleMapClick}/>
                         </div>
                    </div>
                );
              } else {
                return (
                  <FullScreenMap {...row} openMap={this.handleMapClick}/>

                );
              }
        }
    }

}


export default Practice;

//https://portale.fnomceo.it/cerca-prof/index.php
