import React from 'react';
import Button from './Button';
import { useMediaQuery } from 'react-responsive'; // npm install react-responsive --save

function BtnTopBar(props) {

    //media.isPortrait =  useMediaQuery({ query: '(orientation: portrait)' });
    //media.isLandscape =  useMediaQuery({ query: '(orientation: landscape)' });
    const isSmarthPhone = useMediaQuery({ maxWidth: 734 });
    const isTablet = useMediaQuery({ maxWidth: 1068, minWidth: 735 });
  /******* Media Queries ****/

    const iconSize = isSmarthPhone ? "29px" : "24px";
    const btnTopSize = (props.svg && !props.text) ? "a-btn-top-icon" : ((props.svg && props.text) ? "a-btn-top-icon-text" : "a-btn-top");
    const btnTopNoBkgSize = (props.svg && !props.text) ? "a-btn-top-icon" : ((props.svg && props.text) ? "a-btn-top-icon-text" : "a-btn-top");
    switch (props.buttonStyle) {
      case "filled-dark": return  <Button size={btnTopSize}  filling="a-btn-filled-dark"  {...props}  />;
        break;
      case "filled": return  <Button size={btnTopSize}  filling="a-btn-filled" {...props}  />;
        break;
      case "textOnHover": return  <Button size={btnTopSize}  filling="a-btn-textOnHover"  {...props} />;
        break;
      case "bordOnHover": return  <Button size={(props.icon && !props.text) ? "a-btn-top-icon-noBkg" : "a-btn-top-noBkg"}  filling="a-btn-bordOnHover" {...props} />;
        break;
      case "bordered": return  <Button size={(props.icon && !props.text) ? "a-btn-top-icon-noBkg a-btn-margin" : "a-btn-top-noBkg a-btn-margin"}  filling="a-btn-bordered" {...props} />;
        break;
      case "filledWithMargin":  return  <Button size={(props.icon && !props.text) ? "a-btn-top-icon a-btn-margin" : "a-btn-top a-btn-margin"}  filling="a-btn-HW" {...props} />;
        break;
      default: return  <Button size={(props.icon && !props.text) ? "a-btn-top-icon" : "a-btn-top"}  filling="a-btn-filled-dark" {...props} />;

    }

}


export default BtnTopBar;
