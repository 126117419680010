import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './menuButton.css';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
const LeftIcon = (props) => (props.icon) ? <div className="no-shrink side-menu-link-icon"><SvgIcon icon={props.icon} color={props.color}/></div> : null;
const SubMenuIcon = (props) => (props.subMenu) ? <div className=" no-shrink w3-margin-left-6 side-menu-sub-icon" ><SvgIcon color={props.color} icon="arrow_forward_ios"/></div> : null;

function MenuButton(props) {

    function handleClick() {
      if (props.click) {
        if (props.id) {
          props.clickHandler(props.id);
        }  else {
          props.clickHandler();
        }
      } else if (props.subMenu){
        props.subHandler(props.title, props.subMenu);
      }

    }

    if (!props.title) {
      return null;
    } else {
      const click = (props.click || props.subMenu) ? handleClick : false;
      return (
          <li>
              <Link className={props.className} href={props.href} click={click} >
                  <div className="flex-cen-cen side-men-it">
                        <LeftIcon icon={props.icon} color={props.iconBarColor}/>
                        <div className="side-menu-text"><span>{props.title}</span></div>
                        <SubMenuIcon subMenu={props.subMenu} color={props.iconBarColor} />
                  </div>
              </Link>
          </li>
      );
    }
}

export default MenuButton;
