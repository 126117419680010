import React from 'react'; import './contact.css';
import Button from '../Buttons/Button';import PrBoxEl from './PrBoxEl';

/*
    <div className=""></div>
*/


function Contatti(props) {
   if (!props.phone) {
     return null;
   } else {
     return (
       <div className="practice-box-container">

            <div className="pr-cont-box ">
              <div className="pr-box-padding ">
                  <h4 className="pr-box-title">Contattaci</h4>
                      <PrBoxEl h4="Telefono" text={props.phone} />
                      <PrBoxEl h4="Whatsapp" text={props.whatsapp} />
                      <PrBoxEl h4="E-mail" text={props.email} />
              </div>

            </div>
       </div>
     );
   }
}


export default Contatti;
