import React from 'react';
import "../w3.css";
import "../luke.css";
import "../App.css";
import './previous.css';
import {Icon} from '../Blocks/Blocks';
import Link from '../Link';

function Previous(props) {
    if (!props.previous) {
        return (
          <div className="sid-men-prev-top-cont ">
              <div className="sid-men-prev-cont">
                  <div className="flex-cen-cen side-men-root">

                        <div className="w3-margin-left-8 grow-1 truncate"><span className="">Main Menu</span></div>

                  </div>
              </div>
              <hr className="previous-separator"/>
          </div>
        );
    } else {
        return (
          <div className="sid-men-prev-top-cont ">
              <div className="sid-men-prev-cont">
                  <Link className="" href={props.href} click={props.handleBack} >
                      <div className="flex-cen-cen side-men-back">

                            <Icon icon="arrow_back" size="25px"/>
                            <div className="w3-margin-left-8 grow-1 truncate"><span className="">{props.previous}</span></div>

                      </div>
                  </Link>
              </div>
              <hr className="previous-separator"/>
          </div>
        );
    }
}

export default Previous;
