import React from 'react';
import Button from './Button';
import {Icon} from '../Blocks/Blocks';
import Link from '../Link';
import './dropDown.css';

// <div className=""></div>

function DropLi(props) {
    if (false) {
      return null;
    } else {
      const click = props.click ? [props.clickHandler, props.id] : null;
      return (
         <li className="a-btn-drop-row ">
            <Link href={props.href} click={click}>
              {props.text}
            </Link>
         </li>
      );
    }
}

function DropDown(props) {
  if (!props.text) {
    return null;
  } else {
    return (
        <div className="flex-cen-cen h-100">
              <div>{props.text}</div>
              <Icon icon="arrow_drop_down" className="w3-margin-left-2" size="22px" />
              <ul className={"a-btn-drop-menu w3-animate-opacity " + (props.dropDefs ? props.dropDefs : "a-btn-drop-defs ") + (props.dropRight ? " drop-right " : " drop-left ")}>
                  {props.dropRows.map((li, index) => <DropLi {...li} key={index} clickHandler={props.clickHandler}/>)}
              </ul>
        </div>
    );
  }

}

export default DropDown;


/*
<DropDown text="" dropRight={bol} dropRows dropDefs="a-btn-drop-defs"/>
dropRight={bol}  default is false, it measn it drops to the left: right=0 if with>100% it will overflow to the left. dropRight should be true only for drop downs located on the immediate left where there is no space to overflow to the left so we make it overflow to the right with left=0

/***************** Default class props.dropDefs=""a-btn-drop-defs""
to change defaults rename and change the def class: .a-btn-drop-defs {--dropWidth: 200px; --dropFontCol:rgb(15, 15, 18);--dropUlBkg: white; --dropUlBkgHov: #f5f5f5;--dropBrdRadius: 6px; --dropBoxShColor: rgba(0,0,0,.7);
  --toolTipSize: 6px; --toolTipRightMarg: 14px; --toolTipLeftMarg: 25%; --dropFontSize: 14px; --dropLineHe: 17px; --dropLiPadding: 14px 14px;--dropFontWeigh: 500}
*/
